<template>
    <v-app class="desktopLayoutClass">
        <div class="d-flex header pt-4 pl-5" style="position: sticky; top: 0; z-index: 3; background: white;" v-show="show_header">
            <div style="display: flex; flex-direction: row; width: 100%">
                <div style="width: 10%; padding-top: 6px" align="left" v-on:click="redirectToAllSubs()">
                    <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
                </div>
                <div style="width: 90%" align="left">
                    <span style="font-weight: 600; font-size: 15pt">{{ pageTitleName }}</span>
                </div>
            </div>
        </div>
        <div v-if="!isLoadingPageData">
            <!-- PACKAGE BANNER: -->
            <div v-if="this.bannerName != null" class="pa-4">
                <img :src="imageURL" alt="Package Banner" class="banner">
            </div>

            <div class="pa-4" style="margin-top: -20px;">
                <div class="particularCorporateBackground mb-4" v-if="false">
                <div class="d-flex pa-4">
                    <div class="expiryDateBlock">
                        <div class="d-flex flex-column expiryDate">
                            <div>
                                <span class="normaltext">{{ $t ("Customer.MySubscription.Package_Expires") }}:</span>
                            </div>
                            <div>
                                <span style="font-weight: 600">{{ expiryDate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="redemptionsLimitBlock d-flex flex-column">
                        <div class="redemptionValue pa-1">
                        <span class="redemptionRemaining">{{ parseInt(redemptionRemainingForPackage) < 0 ? totalRedemptionForPackage : redemptionRemainingForPackage  }}</span> <span class="totalRedemption">/ {{ totalRedemptionForPackage }}</span>
                        <div class="bookingLeftText">{{ $t ("Customer.MySubscription.Booking_Left") }}</div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="d-flex justify-space-around">
                    <div style="font-size: 15px;">
                        <p style="">{{ $t ("Customer.MySubscription.Expires_On") }}:</p>
                        <p style="font-weight: 600; font-size: medium; margin-top: -20px;">{{ expiryDate }}</p>
                    </div>
                    <div style="font-size: 15px;">
                        <p>{{ $t ("Customer.MySubscription.Booking_Left") }}:</p>
                        <p style="font-weight: 600; font-size: medium; margin-top: -20px;">{{ parseInt(redemptionRemainingForPackage) < 0 ? totalRedemptionForPackage : redemptionRemainingForPackage  }}/ {{ totalRedemptionForPackage }}</p>
                    </div>
                </div>
            </div>

            <div class="pa-4 packageDeets" style="margin-top: -30px;">
                <div class="descBox">
                    <div>
                        <span class="descTitle">{{ $t ("Customer.MySubscription.Package_Details") }}</span>
                    </div>
                    <div>
                        <span v-if="!readMore">{{ packageDesc.slice(0,100) }}</span>
                        <div v-if="packageDesc.length > 100">
                            <span v-if="!readMore" @click="readMore = true" class="hoverHand">
                                <b style="color: #1467BF;">{{ $t ("Customer.MySubscription.Read_More") }}</b>
                            </span>
                            <span v-if="readMore">{{ packageDesc.slice(0, packageDesc.length) }}</span>
                            <span v-if="readMore" @click="readMore = false" class="hoverHand">
                                <b style="color: #1467BF;"> {{ $t ("Customer.MySubscription.Read_Less") }}</b>
                            </span>
                        </div>
                    </div>
                </div>
                
            </div>
            <v-divider></v-divider>
            <div v-if="noBenefitsFlag" style="display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 60px 0;
                text-align: center;"
            >
                <img src="https://s3iconimages.mymedicine.com.mm/alert.svg" alt="No Benefits" style="width: 64px; height: 64px; margin-bottom: 16px;">
                <p style="font-size: 16px; color: #666;">No Benefit Has been added for this package at current moment</p>
            </div>
            <div v-else>
                <v-divider></v-divider>
                <v-tabs v-model="benefitTab" :ripple="true" class="fixed-tabs-bar benefitTabClass"  return-object>
                    <v-tabs-slider :ripple="true"></v-tabs-slider>
                    <v-tab v-for="benefit, index in benefitData" :key="index" 
                        :href="'#'+String(index)" 
                        @click=" getPackageType(benefit), primaryLoadIndex = 0 " 
                        class="benefitNameText py-4 d-flex align-center" 
                        v-model="tabModel"
                        :disabled="isTabdataLoading"
                        :ripple="false"
                        >
                        <div v-if = "benefit.fup_benefit"> {{ $t ("Customer.MySubscription.Extra_Benefits") }}</div>
                        <div v-else> 
                            {{ $t ("Customer.MySubscription.Benefit") }} {{ index + 1 }}
                        </div>
                    </v-tab>
                </v-tabs>
                <v-divider></v-divider>
            </div>
            
            <v-tabs-items class="pt-4" v-model="benefitTab"  @change="getPackageType(benefitData[parseInt(benefitTab.charAt(benefitTab.length - 1))]), primaryLoadIndex = 0" >
                <v-tab-item v-for="specData, index in benefitData" :key="index" :id="String(index)">
                <div v-if="parseInt(specData.availableCount) > 0 || specData.fup_benefit">
                    <div class="py-2 d-flex flex-row specializationInBenefit" v-if="specData.package_type === 'Specializations'">
                        <v-slide-group>
                            <v-slide-item v-for="spec, idx in specData.specRecords" :key="spec.name" v-slot="{ active, toggle }">
                                <v-card class="py-4 px-4" :disabled="isParticularSpecLoading" color="transparent"  flat>
                                    <div class="d-flex flex-column align-center" @click="doctor_list=[], getDoctorListLocal(spec._id, specData.id,specData.availableCount, specData.fup_benefit), primaryLoadIndex = idx" >
                                        <div class="d-flex justify-center align-center" :class=" (idx === primaryLoadIndex ) ? 'specializationBackgroundActive': 'specializationBackgroundInactive'" @click="toggle">
                                            <img class="specializationIcon" :src="idx === primaryLoadIndex ? 'https://s3iconimages.mymedicine.com.mm/' + spec.active_img_name: 'https://s3iconimages.mymedicine.com.mm/' + spec.image_filename">
                                        </div>
                                        <div class="pt-2" v-if="$store.state.locale == 'en'" :class="idx === primaryLoadIndex ? 'specializationNameTextActive' : 'specializationNameTextInactive'" @click="toggle">
                                            {{ spec.specialization_name }}
                                        </div>
                                        <div v-else :class="active? 'specializationNameTextActive' : 'specializationNameTextInactive'" @click="toggle">
                                            {{ spec.specialization_name_mm }}
                                        </div>
                                    </div>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </div>
                    <div class="py-2 d-flex flex-row specializationInBenefit" v-else>
                        <v-slide-group>
                            <v-slide-item v-for="spec, idx in allSpecializationsForDoctors" :key="idx" v-slot="{ active, toggle }">
                                <v-card class="py-4 px-4" :disabled="isParticularSpecLoading" color="transparent"  flat>
                                    <div class="d-flex flex-column align-center" @click="currentlyActiveSpecializationIndex = idx, doctor_list = specializationDoctor[spec.specialization_name], primaryLoadIndex = idx" >
                                        <div class="d-flex justify-center align-center" :class=" (idx === primaryLoadIndex ) ? 'specializationBackgroundActive': 'specializationBackgroundInactive'" @click="toggle">
                                            <img class="specializationIcon" :src="idx === primaryLoadIndex ? 'https://s3iconimages.mymedicine.com.mm/' + spec.active_img_name: 'https://s3iconimages.mymedicine.com.mm/' + spec.image_filename">
                                        </div>
                                        <div class="pt-2" v-if="$store.state.locale == 'en'" :class="idx === primaryLoadIndex ? 'specializationNameTextActive' : 'specializationNameTextInactive'" @click="toggle">
                                            {{ spec.specialization_name }}
                                        </div>
                                        <div v-else :class="active? 'specializationNameTextActive' : 'specializationNameTextInactive'" @click="toggle">
                                            {{ spec.specialization_name_mm }}
                                        </div>
                                    </div>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </div>
                    <div class="pa-4 d-flex justify-space-between" v-if="!specData.fup_benefit">
                        <div class="d-flex justify-center align-center redemptionUsedBlock pa-2 mx-1" style="width: 30%;">
                            <span>{{ $t ("Customer.MySubscription.Used") }}: 
                                <span class="fontWeightValue">{{specData.promotion_count - specData.availableCount }}</span>
                                /
                                <span>{{ specData.promotion_count }}</span>
                            </span>
                        </div>
                        <div class="offerDescription pa-2 mx-1" v-if="specData.promotion_type === 'PERCENTAGE_OFF'" style="width: 70%;">
                            <span>
                                <span class="fontWeightValue"> {{ specData.discount }}%</span>
                                <span v-if = "specData.minimum_spend > 0">
                                    <span>{{ $t ("Customer.MySubscription.Off_Booking") }}</span>
                                    <span class="fontWeightValue"> {{ $t ("Customer.MySubscription.Off_Booking_Brace") }} {{ specData.minimum_spend }} MMK{{ $t ("Customer.MySubscription.Above") }}</span>
                                </span>
                                <span v-else>
                                    <span>{{ $t ("Customer.MySubscription.Off")}}</span>
                                </span>    
                            </span>
                        </div>
                        <div class="offerDescription pa-2 mx-1" v-else-if="specData.promotion_type === 'FLAT_OFF'" style="width: 70%;">
                            <span >
                                <span class="fontWeightValue"> {{ $t ("Customer.MySubscription.Flat") }} {{ specData.discount}} MMK</span>
                                <span v-if="specData.minimum_spend > 0">
                                    <span > {{ $t ("Customer.MySubscription.Off_Booking") }}</span>
                                    <span class="fontWeightValue"> {{ $t ("Customer.MySubscription.Off_Booking_Brace") }}{{ specData.minimum_spend }} MMK {{ $t ("Customer.MySubscription.Above") }}</span> 
                                </span>
                                <span v-else>{{ $t ("Customer.MySubscription.Off")}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="pa-4 d-flex justify-space-between" v-else>
                        <div class="offerDescription pa-2 mx-1" v-if="specData.promotion_type === 'PERCENTAGE_OFF'">
                            <span v-if="specData.minimum_spend > 0">
                                <span v-if="$i18n.locale === 'mm'">
                                    <span>{{ $t("Customer.MySubscription.Package_Info1_Minium_Spend_Not_0") }}</span>
                                    <span >
                                        <span class="fontWeightValue"> {{ specData.minimum_spend }} MMK</span>
                                        <span> {{ $t ("Customer.MySubscription.Package_Info2_Minium_Spend_Not_0") }}</span>
                                        <span class="fontWeightValue"> {{ specData.discount}} %</span>
                                        <span> {{ $t ("Customer.MySubscription.Package_Info3_Minium_Spend_Not_0") }}</span>
                                    </span>
                                </span>
                                <span v-else>
                                    <span>{{ $t("Customer.MySubscription.Package_Info1_Minium_Spend_Not_0") }}</span>
                                    <span >
                                        <span class="fontWeightValue"> {{ specData.discount}} %</span>
                                        <span> {{ $t ("Customer.MySubscription.Package_Info2_Minium_Spend_Not_0") }}</span>
                                        <span class="fontWeightValue"> {{ specData.minimum_spend }} MMK</span>
                                    </span>    
                                </span>
                            </span>
                            <span v-else>
                                <span>{{ $t("Customer.MySubscription.Package_Info1_Minium_Spend_0") }}</span>
                                <span >
                                    <span class="fontWeightValue">{{ specData.discount}} %</span>
                                    <span> {{ $t ("Customer.MySubscription.Minimum_Spend_0_Off_Booking") }}</span>
                                </span>
                            </span>
                        </div>
                        <div class="offerDescription pa-2 mx-1" v-else-if="specData.promotion_type === 'FLAT_OFF'">
                            <span v-if="specData.minimum_spend>0">
                                <span v-if="$i18n.locale === 'mm'">
                                    <span>{{ $t("Customer.MySubscription.Package_Info1_Minium_Spend_Not_0") }}</span>
                                    <span >
                                        <span class="fontWeightValue"> {{ specData.minimum_spend }} MMK</span>
                                        <span> {{ $t ("Customer.MySubscription.Package_Info2_Minium_Spend_Not_0") }}</span>
                                        <span class="fontWeightValue"> {{ specData.discount}} MMK</span>
                                        <span> {{ $t ("Customer.MySubscription.Package_Info3_Minium_Spend_Not_0") }}</span>
                                    </span>
                                </span>
                                <span v-else>
                                    <span>{{ $t("Customer.MySubscription.Package_Info1_Minium_Spend_Not_0") }}</span>
                                    <span >
                                        <span class="fontWeightValue"> {{ specData.discount}} MMK</span>
                                        <span> {{ $t ("Customer.MySubscription.Package_Info2_Minium_Spend_Not_0") }}</span>
                                        <span class="fontWeightValue"> {{ specData.minimum_spend }} MMK</span>
                                    </span>    
                                </span>
                            </span>
                            <span v-else>
                                <span>{{ $t("Customer.MySubscription.Package_Info1_Minium_Spend_0") }}</span>
                                <span >
                                    <span class="fontWeightValue">{{ specData.discount}} MMK</span>
                                    <span> {{ $t ("Customer.MySubscription.Minimum_Spend_0_Off_Booking") }}</span>
                                </span>
                            </span>
                        </div>
                    </div>

                    <!-- <div class="pa-4" v-if="specData.frequency != null && !specData.frequencyExhausted">
                        <div class="useageConditionBlock rounded pa-2 mx-1">
                            <p class="conditionsText ma-0"> <span class="font-weight-bold"> {{ $t("Customer.MySubscription.Condition") }}: </span> <span> {{ specData.frequency.booking_count }} {{ $t("Customer.MySubscription.Bookings") }} / {{ specData.frequency.number_of_days }} {{ $t("Customer.MySubscription.Days") }} </span></p>
                        </div>
                    </div>
                    <div v-else-if="specData.frequency != null && specData.frequencyExhausted">
                        <div class="pa-4">
                            <div class="BenefitLimitReached pa-2 mx-1 rounded">
                                <div class="d-flex justify-start align-center">
                                    <img class="pa-1" src="https://s3iconimages.mymedicine.com.mm/redInfoIcon.svg">
                                    <p class="ma-0 benefitReachedLimitText">{{ $t("Customer.MySubscription.Benefit_Limit_Reached") }}</p> 
                                </div>
                            <p class="benefitReachedLimitText pl-6 ma-0">{{ $t("Customer.MySubscription.Next_Usage") }} <span class="fontWeightValue">{{ specData.nextUsage }}</span></p>
                            </div>
                        </div>
                    </div> -->
                    <div class="pa-4" v-if="specData.frequency != null && !specData.frequencyExhausted && !specData.frequency_end_date">
                        <div class="useageConditionBlock rounded pa-2 mx-1">
                            <p class="conditionsText ma-0"> <span class="font-weight-bold"> {{ $t("Customer.MySubscription.Condition") }}: </span> <span> {{ specData.frequency.booking_count }} {{ $t("Customer.MySubscription.Bookings") }} / {{ specData.frequency.number_of_days }} {{ $t("Customer.MySubscription.Days") }} </span></p>
                        </div>
                    </div>
                    <div class="pa-4" v-else-if="specData.frequency != null && !specData.frequencyExhausted && specData.frequency_end_date">
                        <div class="useageConditionBlock rounded pa-2 mx-1">
                            <p class="conditionsText ma-0">
                                <span class="font-weight-bold"> {{ $t("Customer.MySubscription.Note") }}: </span>
                                <span> {{ $t("Customer.MySubscription.Avail_Info_1") }} <b>{{ specData.frequency.booking_count }}</b> {{ $t("Customer.MySubscription.Avail_Info_2") }} <b>{{ specData.frequency.number_of_days }}</b> {{ $t("Customer.MySubscription.Avail_Info_3") }}. </span>
                                <span v-if="specData.frequency_used != 0">{{ $t("Customer.MySubscription.Availed_Info_1") }} <b>{{ specData.frequency_used }}</b> <span>{{ $t("Customer.MySubscription.Availed_Info_2") }} </span></span>
                                <span>{{ $t("Customer.MySubscription.Availed_Info_3") }}</span>
                                <span>{{ specData.frequency_end_date }}.</span>
                            </p>
                        </div>
                    </div>
                    <div class="pa-4" v-else-if="specData.frequency != null && specData.frequencyExhausted">
                        <div class="pa-4">
                            <div class="BenefitLimitReached pa-2 mx-1 rounded">
                                <div class="d-flex justify-start align-center">
                                    <img class="pa-1" src="https://s3iconimages.mymedicine.com.mm/redInfoIcon.svg">
                                    <p class="ma-0 benefitReachedLimitText">{{ $t("Customer.MySubscription.Benefit_Limit_Reached") }}</p> 
                                </div>
                            <p class="benefitReachedLimitText pl-6 ma-0">{{ $t("Customer.MySubscription.Next_Usage") }} <span class="fontWeightValue">{{ specData.nextUsage }}</span></p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div v-if="loadingDotorFlag">
                            <div v-if="doctor_list.length > 0" style="margin-top:10px; display:flex; flex-direction: column; flex-wrap: wrap; justify-content:space-evenly;  padding-bottom:20px;">
                                <div class="pb-4 px-4" v-for="doctor in doctor_list" :key="doctor._id">
                                    <doctorsComponent :doctor="doctor" :pageName="pageName"></doctorsComponent>
                                </div>
                            </div>
                            <div v-else style="margin:0 10%">
                                <p style="padding: 10%" class="noDoctorFound" >{{ $t("Customer.ViewDoctorsPage.No_Doctors_Found") }}</p>
                            </div>
                        </div>
                        <div v-else>
                            <v-progress-circular
                            indeterminate
                            color="primary">
                            </v-progress-circular>
                        </div>
                    </div>
                </div>
                <div v-else class="pa-4">
                    <div class="px-4 py-10 benefitCompletedBackground d-flex flex-column"> 
                        <div>
                            <img src="https://s3iconimages.mymedicine.com.mm/successIconPackage.svg">
                        </div>
                        <div>
                            <span class="benefitCompletedText">{{ $t ("Customer.MySubscription.Benefit_Completed") }}</span>
                        </div> 
                        <div>
                             <span class="benefitsUsedText">{{ $t ("Customer.MySubscription.Benefit_Used") }}: <span class="usedBenefitCount">{{ (specData.promotion_count - specData.availableCount) > specData.promotion_count ? specData.promotion_count : (specData.promotion_count - specData.availableCount) }}/{{ specData.promotion_count }}</span></span>
                        </div>
                    </div>
                    <div class="px-4 py-5 my-4 benefitBackground">
                        <p class="benefitTitleText">{{ $t ("Customer.MySubscription.Benefit") }}</p>
                        <ul class="listOfBenefit">
                            <li v-if="specData.promotion_type === 'PERCENTAGE_OFF'"> {{ specData.discount}}% {{ $t ("Customer.MySubscription.Off_Booking") }} {{ $t ("Customer.MySubscription.Off_Booking_Brace") }} {{ specData.minimum_spend }} MMK {{ $t ("Customer.MySubscription.Above") }}</li>
                            <li v-else>{{ $t ("Customer.MySubscription.Flat") }} {{ specData.discount}} MMK {{ $t ("Customer.MySubscription.Off_Booking") }}{{ $t ("Customer.MySubscription.Off_Booking_Brace") }}{{ specData.minimum_spend }} MMK {{ $t ("Customer.MySubscription.Above") }}</li>
                            <li v-if="specData.package_type === 'Specializations'"> {{ $t ("Customer.MySubscription.Specialization_Includes") }}: {{ formattedSpecialization }} </li>
                        </ul>
                    </div>
                </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <div v-else class="pt-10">
            <v-progress-circular
            indeterminate
            color="primary">
            </v-progress-circular>
        </div>
    </v-app>
</template>

<script>
import axios from "axios";
import { handleError } from '../../../utils/utils';
import { axios_auth_instance_customer } from '../../../utils/axios_utils';
export default{
    data(){
        return{
            pageName: "ViewParticularSubscription",
            currentCustomer:"",
            show_header: true,
            corporate_package: {},
            isPageLoading: true,
            expiryDate:null,
            redemptionRemainingForPackage: null,
            totalRedemptionForPackage: null,
            frequency: {},
            allBenefits: [],
            pageTitleName: 'PackageName',
            mmJSONdata: "",
            defaultIndex: 0,
            benefitTab:'0',
            primaryLoadIndex: 0,
            doctor_list: [],
            loadingDotorFlag: false,
            isLoadingPageData: true,
            benefitData: [],
            listOfDoctorForSpec:{},
            allDoctorData: [],
            specilizationList: [],
            tabModel:null,
            swipeControl:'',
            setSpecializationList: [],
            isTabdataLoading: false,
            isParticularSpecLoading: true,
            doctorDataSpecializationWise: {},
            activeBenefit: null,
            specializationDoctor: {},
            allSpecializationKeys: [],
            allSpecializationsForDoctors: [],
            currentlyActiveSpecializationIndex: 0,
            description: '',
            descriptionMM: '',
            bannerName: '',
            packageDesc: '',
            readMore: false,
            imageURL: '',
            isDependentAllowed: false,
            noBenefitsFlag: false
        }
    },
    components:{
        doctorsComponent: ()=> import('../../../components/doctorsComponent.vue')
    },
    computed: {
        formattedSpecialization() {
            this.specilizationList = [];
            if (this.$i18n.locale  === 'mm') {
                this.setSpecializationList.forEach((specData) => {
                    this.specilizationList.push(specData.specialization_name_mm);
                });
                return this.specilizationList.join(', ');
            } else {
                this.setSpecializationList.forEach((specData) => {
                    this.specilizationList.push(specData.specialization_name);
                });
                return this.specilizationList.join(', ');
            } 
        }
    },
    mounted() {
        this.currentCustomer = this.$cookies.get("customerToken");
        if (!this.currentCustomer) {
            this.$router.push({
                name : "Login"
            });
        } else {
            this.pageTitleName = this.$route.params.packageName;
            if(this.$store.state.locale == "en") {
                document.title = "My Subscription";
            } else {
                document.title = "စာရင်းသွင်းထားသောအစီအစဥ်များ";
            }

            var userAgent = navigator.userAgent;
            if (userAgent.includes("kbzpay")) {
                this.show_header = false;
            }

            if (this.$store.state.locale !== "") {
                this.$i18n.locale =  this.$store.state.locale;
            } else {
                this.$i18n.locale = "mm";
                this.$store.dispatch("changeLocale", this.$i18n.locale);
            }
            var susbscriptionBody = {
                token: this.currentCustomer,
                packageId: this.$route.params['packageID'],
                typeOfUser: "CUSTOMER"
            };
            axios.post(process.env.VUE_APP_BACKEND_URL + "/customerViewPackagePage", susbscriptionBody)
            .then((customerSubscriptionsPageResponse)=>{
                let responseForPackage = customerSubscriptionsPageResponse.data.data.corporatePackageInfo;
                this.expiryDate = responseForPackage.end_date;
                this.totalRedemptionForPackage = responseForPackage.total_promotion_count;
                this.redemptionRemainingForPackage = responseForPackage.available_count;
                this.benefitData = responseForPackage.benefits;
                this.description = responseForPackage.description;
                this.descriptionMM = responseForPackage.description_mm;
                this.bannerName = responseForPackage.fileName;
                this.isTabdataLoading = true;
                this.getPackageType(this.benefitData[0]);
                this.isLoadingPageData = false;
                this.userPageViewTable();

                if(this.$store.state.locale == "en") {
                    this.packageDesc = this.description;
                } else {
                    this.packageDesc = this.descriptionMM;
                }
                
                // console.log("Banner name: ", this.bannerName);
                //For BANNER-PRESIGNED-URL-READ
                if(this.bannerName == null) {
                    // console.log("No banner exists");
                } else {
                    // console.log("Banner exists: ", this.bannerName);

                    var readBody = {
                        file_name: this.bannerName,
                        token: this.currentCustomer,
                        typeOfUser: "CUSTOMER",
                    }
                    
                    axios.post(process.env.VUE_APP_BACKEND_URL + "/getBannerFromS3ForCustomer", readBody)
                    .then((readSuccess) => {
                        // console.log("readSuccess: ", readSuccess);
                        this.imageURL = readSuccess.data.data;
                        this.isParticularSpecLoading = false;
                        // console.log("URL = ", this.imageURL);
                    })
                    .catch((readError) => {
                        this.isParticularSpecLoading = false;
                        console.log("readError: ", readError);
                    })
                };
                this.isParticularSpecLoading = false;
                // console.log("desc, descmm:: ", this.description, " ", this.descriptionMM); 
                if (customerSubscriptionsPageResponse.data.data.corporatePackageInfo && 
                    customerSubscriptionsPageResponse.data.data.corporatePackageInfo.benefits && 
                    customerSubscriptionsPageResponse.data.data.corporatePackageInfo.benefits.length > 1 &&
                    customerSubscriptionsPageResponse.data.data.corporatePackageInfo.benefits[1].isDependentAllowed === false) 
                {
                    this.isDependentAllowed = false;
                } else {
                    this.isDependentAllowed = true;
                }
                this.$store.dispatch("changeDependency", this.isDependentAllowed);
            }).catch((customerSubscriptionsPageError)=>{
                console.log("customerSubscriptionsPageError",customerSubscriptionsPageError);
                if(customerSubscriptionsPageError.status !== 200){
                    alert('ERROR');
                    this.$router.go(-1)
                }
                this.isLoadingPageData = false ;
                this.isParticularSpecLoading = false;
            });
        };
    },
    methods:{
        expandBenefit(benefit_object) {
            benefit_object['show'] = !benefit_object['show'];
        },
        redirectToAllSubs() {
            this.$router.go(-1);
        },
        getDoctorList(specializationId, benefitId, availableCount) {
            this.isTabdataLoading = true
            this.isParticularSpecLoading = true
            var getBenefitDoctorsBody = {
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
                corporatePackageId: this.$route.params['packageID'],
                benefitId: String(benefitId),
                specializationId: specializationId
            };
            axios_auth_instance_customer.post("/getBenefitDoctors", getBenefitDoctorsBody)
            .then((getBenefitDoctorsResponse) => {
                this.isTabdataLoading = false;
                this.allDoctorData = getBenefitDoctorsResponse.data.doctors;
                if(this.$i18n.locale  === 'mm') {
                    this.allDoctorData.forEach((doctorData) => {
                        let doctorItem = {
                            doctor_id: doctorData.doctor_id,
                            doctor_name: doctorData.doctor_name_mm,
                            doctor_profile_picture: doctorData.doctor_profile_picture,
                            fee: doctorData.fee,
                            specialization_name: doctorData.specialization_name_mm,
                            _id: doctorData._id
                        };
                        this.doctor_list.push(doctorItem);
                    });
                } else {
                    this.allDoctorData.forEach((doctorData) => {
                        let doctorItem = {
                            doctor_id: doctorData.doctor_id,
                            doctor_name: doctorData.doctor_name,
                            doctor_profile_picture: doctorData.doctor_profile_picture,
                            fee: doctorData.fee,
                            specialization_name: doctorData.specialization_name,
                            _id: doctorData._id
                        };
                        this.doctor_list.push(doctorItem);
                    });
                }   
                // this.doctor_list = getBenefitDoctorsResponse.data.doctors;
                this.loadingDotorFlag = true;
                this.isParticularSpecLoading = false;
                if (this.listOfDoctorForSpec[benefitId]) {
                    this.listOfDoctorForSpec[benefitId][specializationId] = this.doctor_list;
                } else {
                    this.listOfDoctorForSpec[benefitId] = {
                        [specializationId]: this.doctor_list
                    };
                }
            }).catch((getAllWalkInDoctorsError) => {
                this.isTabdataLoading = false;   
                this.isParticularSpecLoading = false;
                this.loadingDotorFlag = true;
                handleError(getAllWalkInDoctorsError, this.$router, 'getAllWalkInDoctorsError', 'Login', 'customerToken');
            });
        },
        getDoctorListLocal(specializationId, benefitId, availableCount, fup_benefit = false) {
            this.doctor_list = [];
            this.isTabdataLoading = true;
            this.loadingDotorFlag = false;
            benefitId = benefitId ? benefitId : ( fup_benefit ? 'fup' : '' );
            // this.isParticularSpecLoading = false
            if ( (Object.keys(this.listOfDoctorForSpec).length !== 0 && this.listOfDoctorForSpec[benefitId] && this.listOfDoctorForSpec[benefitId][specializationId])) {
                this.isTabdataLoading = false;
                this.doctor_list = this.listOfDoctorForSpec[benefitId][specializationId];
                this.isParticularSpecLoading = false;
                this.loadingDotorFlag = true;
            } else {
                if (!benefitId) {
                    this.getDoctorList(specializationId, 'fup', availableCount);
                } else {
                    this.getDoctorList(specializationId, benefitId, availableCount);
                }
                
            }
        }, 
        getPackageType(benefitData) {
            if(!benefitData){
                this.noBenefitsFlag = true;
                return
            } 
            this.doctor_list = [];
            this.currentlyActiveSpecializationIndex = 0;
            this.allSpecializationKeys = [];
            this.specializationDoctor = {};
            this.allSpecializationsForDoctors = [];
            if (benefitData.package_type === 'Specializations') {
                this.isParticularSpecLoading = true;
                this.setSpecializationList = benefitData.specRecords;
                if (benefitData.availableCount > 0 || benefitData.fup_benefit) {
                    this.getDoctorListLocal(benefitData.specRecords[0]._id, benefitData.id, benefitData.availableCount ? benefitData.availableCount : 0, benefitData.fup_benefit);
                } else {
                    this.isTabdataLoading = false;
                }
            } else if (benefitData.package_type === 'Doctors' && this.benefitData.availableCount !== 0) {
                this.loadingDotorFlag = true;
                this.isTabdataLoading = false;
                this.allDoctorData = benefitData.docRecords;
                this.activeBenefit= benefitData;
                this.allDoctorData.forEach((doctorData) => {
                     if (this.$i18n.locale  === 'mm') {
                            doctorData = {
                                ...doctorData,
                                doctor_name: doctorData.doctor_name_mm,
                                specialization_name: doctorData.specialization_name_mm
                            };
                        }
                    if (this.specializationDoctor.hasOwnProperty(doctorData.specialization_name)) {
                        this.specializationDoctor[doctorData.specialization_name].push(doctorData);
                    } else {
                        this.specializationDoctor[doctorData.specialization_name] = [];
                        const specializationData = {
                            specialization_name: doctorData.specialization_name,
                            specialization_name_mm: doctorData.specialization_name_mm,
                            active_img_name: doctorData.active_img_name,
                            image_filename: doctorData.image_filename,
                            specialization_id: doctorData.specialization_id 
                        }
                        this.allSpecializationsForDoctors.push(specializationData);
                        if (this.$i18n.locale  === 'mm') {
                            this.specializationDoctor[doctorData.specialization_name].push({
                                ...doctorData,
                                doctor_name: doctorData.doctor_name_mm,
                                specialization_name: doctorData.specialization_name_mm
                            });
                        } else {
                            this.specializationDoctor[doctorData.specialization_name].push(doctorData);
                        }
                    }
                });
                this.doctor_list = this.allSpecializationsForDoctors.length ? this.specializationDoctor[this.allSpecializationsForDoctors[0].specialization_name] : [];
                this.currentlyActiveSpecializationIndex = 0;
                this.allSpecializationKeys = Object.keys(this.specializationDoctor);
            } else if(benefitData.availableCount == 0) {
                this.isTabdataLoading = false;
            }
        },
        userPageViewTable() {
            let userPageViewTableBody;
            if (this.currentCustomer === null) {
                userPageViewTableBody = {
                    pageName : 'ParticularPackagesPage',
                    typeOfUser: 'CUSTOMER'
                };
            } else {
                userPageViewTableBody = {
                    pageName: 'ParticularPackagesPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.currentCustomer
                };
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
    }
}
</script>

<style>
</style>

<style scoped>
.banner {
    width: 100%;
    border-radius: 8px;
    position: relative;
    background-size: cover;
    
}
.hoverHand {
    cursor: pointer;
}
.descTitle {
    font-size: large;
    font-weight: 700;
}

.descText {
    font-weight: 350;
}

.packageDeets{
    text-align: left;
}

.normaltext {
    font-size: 12px;
}

.specializationIcon {
    width: 42px;
    height: 42px;
}
.particularCorporateBackground{
    background-image: url("https://s3iconimages.mymedicine.com.mm/corporateStatusBar.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 8px;
    position: relative;
}
.expiryDateBlock{
    width: 70%;
}   
.expiryDate{ 
    color: white;
    text-align: left;
}
.redemptionsLimitBlock{
    background-color: #1467BF;
    position: absolute;
    right: 0;
    top: 0;
    width: 35%;
    opacity: 10;
    border-radius: 0 8px 8px 0; 
    height: 100%;
    filter: opacity(85%);
}
.redemptionRemaining{
  color: white;
  font-size: 24px;
  font-weight: 700;
  filter: opacity(100%);
}
.totalRedemption{
  color: white;
  font-size: 12px;
  filter: opacity(100%);
}
.bookingLeftText{
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  filter: opacity(100%);
}
.benefitNameText{
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
}
.specializationInBenefit{
    background: #F4F6FA;
    padding: unset !important
}
.specializationBackgroundActive{
    background-color: #1467BF;
    width: 55px;
    height: 55px;
    border-radius: 50%;
}
.specializationBackgroundInactive{
    background-color: #E4F5FF;
    width: 55px;
    height: 55px;
    border-radius: 50%;
}
.specializationNameTextActive{
    max-width: 80px;
    font-size: 0.7em;
    font-weight: 600;
    display: -webkit-box;
    word-wrap: break-word;
    white-space: normal;
    hyphens: auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    line-break: loose;
    color: #1467BF;
}
.specializationNameTextInactive{
    max-width: 85px;
    font-size:  0.7em;
    font-weight: 500;
    display: -webkit-box;
    word-wrap: break-word;
    white-space: normal;
    hyphens: auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    line-break: loose;
    color: #333333;
}
.redemptionUsedBlock{
    background: #DEEEFF;
    width: 30%;
    border-radius: 4px;
    font-size: 14px;
    color: #1467BF;
}
.offerDescription{
    background: #57EB5D33;
    border-radius: 4px;
    font-size: 12px;
    color: #219653;
}
.fontWeightValue{
    font-weight: 700;
}
.benefitCompletedBackground{
    background: #40F4681A;
    text-align: left;
    border-radius: 8px;
}
.benefitCompletedText{
    font-size: 22px;
    font-weight: 600;
    color: #219653;
}
.benefitsUsedText{
    font-size: 14px;
    line-height: 3;
    color: #219653;
}
.usedBenefitCount{
    font-size: 14px;
    font-weight: 600;
    color: #219653;
}
.benefitTitleText {
    color: #1467BF;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;

}

.listOfBenefit{
    color: #1467BF;
}
.benefitBackground{
    background-color: #DEEEFF;
    text-align: left;
    border-radius: 8px;
}
.noDoctorFound{
    font-size: 18px;
    font-weight: bold;
    line-height: 1px;
}
.useageConditionBlock{
    background: #F890351F;
    
}
.conditionsText{
    color: #F89035;
    font-size: 12px;
    text-align: left;
}
.BenefitLimitReached {
    border: solid 1px #EB5757;
    text-align: left;
}
.benefitReachedLimitText{
    color: #EB5757;
    font-size: 12px;
}
</style>
<style>
.benefitNameText .v-tab {
    opacity: 0 !important;
}
.benefitTabClass.fixed-tabs-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 2;
}
.benefitTabClass.v-slide-group__prev.v-slide-group__prev--disabled{
    display: none !important;
}
.benefitTabClass.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev{
    display: none !important;
}
</style>